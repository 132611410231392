/**
 * @component Section component
 */

.section.is-first {
  margin-top: 100px;
  padding-top: $spacing-6;
}

.section.is-full-height {
  min-height: calc(100vh - 170px);
  display: flex;
  align-items: center;
}

.section-header {
  max-width: 500px;
  margin: 0 auto $spacing-6;
  text-align: center;
  position: relative;
  z-index: 10;
}

.section-header__title {
  font-size: 42px;
  font-family: $typeface-secondary;
  color: $color-secondary;
  text-transform: uppercase;
  letter-spacing: 6px;
}

.section-header__subtitle {
  color: $color-secondary-l2;
  font-size: 14px;
  line-height: 2;
}
