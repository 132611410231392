/**
 * @page Homepage
 */

body.is-loading {
  max-height: 100vh;
  overflow: hidden;
}

body[data-page=home]:before {
  content: '';
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: $color-primary;
  pointer-events: none;
  z-index: 95;
  transition: 0.6s linear all;
}

body.is-loaded:before {
  opacity: 0;
}

.home-main {
  background-color: $color-primary;
  position: relative;
}

.home-main > * {
  position: relative;
}

.home-main-backgrounds {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  opacity: 0;
  transition: 1.2s linear all;
}
.home-main-backgrounds.is-loaded {
  opacity: 1;
}

.home-main-backgrounds video {
  position: absolute;
  top: 0;
  width: 100vw;
}

/* Hero
/* -------------------------------------------------------------------------- */
.home-hero {
  // padding-bottom: $spacing-6 * 2;
}

// .home-hero:after {
//   content: '';
//   position: absolute;
//   bottom: 0;
//   right: 0;
//   left: 0;
//   background: linear-gradient(
//     to bottom, rgba($color-primary, 0), $color-primary
//   );
//   height: $spacing-6 * 2;
// }

.home-hero > .container {
  min-height: 85vh;
  padding-top: $spacing-6;
  padding-bottom: $spacing-6;
  @include on-tablet {
    min-height: 100vh;
  }
}

.home-hero-header {
  height: 100%;
  flex-grow: 1;
}

.home-hero-footer {
  opacity: 0;
  transition: 1.2s linear all;
}
.home-hero-footer.is-loaded {
  opacity: 1;
}

.home-hero-footer a:focus {
  outline: 3px solid rgba($color-secondary, 0.2);
}

.home-hero-footer .icon {
  width: 24px;
  height: 24px;
  fill: $color-secondary;
  filter: drop-shadow(0 2px 2px rgba(black, 0.5));
  animation: 1.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) hero-chevron-bob infinite;
}

@keyframes hero-chevron-bob {
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
}


/* Masthead
/* -------------------------------------------------------------------------- */
.masthead {
  transform: scale(0.95);
  transition: 2s cubic-bezier(0.165, 0.84, 0.44, 1) all;
  position: relative;
  z-index: 99;
}

.masthead > span {
  width: 250px;
  position: relative;
  display: block;
  
  @include on-mobile-lg {
    width: 280px;
  }
  
  @include on-tablet {
    width: 400px;
  }
}

.masthead > span:nth-of-type(1) {
  // height: 91px;
  overflow: hidden;
}
.masthead > span:nth-of-type(2) {
  // height: 19px;
}
.masthead > span:nth-of-type(3) {
  // height: 75px;
  overflow: hidden;
}

.masthead img {
  max-width: 100%;
  display: block;
  transition: 2s cubic-bezier(0.075, 0.82, 0.165, 1) all;
}

.masthead > span:nth-of-type(1) > img {
  transform: translateY(100%);
  opacity: 0;
}
.masthead > span:nth-of-type(2) > img {
  transform: scaleX(0);
}
.masthead > span:nth-of-type(3) > img {
  transform: translateY(-100%);
  opacity: 0;
}

.masthead .masthead-glow {
  position: absolute;
  width: 3px;
  height: 3px;
  top: 50%;
  left: 87%;
  transform: translateY(-50%);
  opacity: 0;
}

.masthead .masthead-glow:after {
  content: '';
  position: absolute;
  display: block;
  background: url('../img/masthead/masthead-400px-glow.png');
  background-size: 100% 100%;
  width: 110px;
  height: 110px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// Masthead loader states
.masthead.is-loading {
  & > span:nth-of-type(2) > img {
    transition: 9s cubic-bezier(0.165, 0.84, 0.44, 1) all;
    transform: scaleX(0.6);
  }
}

.masthead.is-loaded {
  transform: scale(1);

  & > span:nth-of-type(1) > img {
    transform: none;
    opacity: 1;
    transition-delay: 1s;
  }

  & > span:nth-of-type(2) > img {
    transform: scaleX(1);
    transition: 2s cubic-bezier(0.165, 0.84, 0.44, 1) all;
  }

  & > span:nth-of-type(3) > img {
    transform: none;
    opacity: 1;
    transition-delay: 1s;
  }

  .masthead-glow {
    animation: 1s cubic-bezier(0.785, 0.135, 0.15, 0.86) masthead-glow-animation forwards;
    animation-delay: 1s;
  }
}

@keyframes masthead-glow-animation {
  0% {
    left: 10%;
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    left: 90%;
    opacity: 0;
  }
}


/* Feature Section
/* -------------------------------------------------------------------------- */
.home-features {
  padding-top: $spacing-6 * 2;
  padding-bottom: $spacing-6 * 2;
  overflow: hidden;
  background-color: $color-primary;
  
  @include on-tablet {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
}

.features-body {
  margin-top: auto;
}

.features-body p,
.feature-description {
  color: white;
  font-size: 14px;
  letter-spacing: 1px;
  max-width: 480px;
  margin: 0 auto;
  text-align: center;
  line-height: 1.6;
  position: relative;
  z-index: 20;
  text-shadow: 0 0 12px rgba(black, 1);
  
  @include on-tablet {
    font-size: 16px;
    text-shadow: 0 0 15px rgba(black, 1);
    line-height: 2;
  }

  @include on-desktop-xlg {
    max-width: 540px;
    font-size: 18px;
  }

  &::selection {
    background-color: $color-secondary;
  }
}

.features-carousel {
  position: relative;
  z-index: 20;
  padding: $spacing-4;
  pointer-events: none;

  @include on-tablet {
    padding: 0;
    height: 100%;
    flex-grow: 1;
    display: flex;
    align-items: center;
  }
}

.feature-item {
  position: relative;
  text-align: center;
  transition: 1.2s linear all;

  @include on-tablet {
    opacity: 0;
  }
}

.slick-current .feature-item {
  opacity: 1;
}

// Carousel Buttons

.features-carousel-controls {
  position: relative;
  height: 48px;
  margin-bottom: $spacing-6;

  @include on-tablet {
    position: static;
    margin-bottom: 0;
    height: auto;
  }
}

.features-carousel-controls__button {
  background-color: $color-primary;
  border: 0;
  fill: white;
  padding: $spacing-4;
  width: 48px;
  height: 48px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 20;

  &:focus {
    outline: 3px solid rgba($color-primary, 0.5);
  }
}

.features-carousel-controls__button .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 18px;
  height: 18px;
}

.features-carousel-controls__button.is-prev {
  left: 0;
}
.features-carousel-controls__button.is-next {
  right: 0;
}

// Feature Title Text

.feature-title {
  font-family: $typeface-secondary;
  font-size: 28px;
  text-transform: uppercase;
  letter-spacing: 3px;
  margin: 0 auto $spacing-6;
  display: inline-block;
  text-align: center;
  
  @include on-tablet {
    text-align: left;
    font-size: 42px;
    letter-spacing: 6px;
    margin-bottom: $spacing-6 * 2;
  }
}

.feature-title-line {
  color: $color-primary;
  display: inline-block;
  opacity: 0;
  transition: 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) all;
}

// .feature-title-line Modifiers
.feature-title-line {

  &.is-colored {
    transform: translateY(2em);
    font-size: 1.2em;
    color: $color-primary;
  }

  &.is-indent-1 {
    transform: translateY(2em);
    transition-delay: 0.4s;
    @include on-tablet {
      margin-left: 4rem;
    }
  }

  &.is-indent-2 {
    transform: translateY(3em);
    transition-delay: 0.8s;
    @include on-tablet {
      margin-left: 8rem;
    }
  }

  &.is-underlined {
    position: relative;
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -$spacing-3;
      height: 2px;
      background-color: $color-primary;

      @include on-tablet {
        left: 2rem;
        right: 3rem;
      }
    }
  }

  small {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1.5rem;
    transform: translateY(1em);
    text-align: center;
    font-size: 12px;
    font-family: $typeface-primary;
    font-weight: normal;
    letter-spacing: 1px;
    
    @include on-tablet {
      left: 2rem;
      right: 3em;
      font-size: 16px;
      letter-spacing: 2px;
    }
  }

}

// Feature line animations
.feature-title-line.animate {
  &.is-colored {
    transform: none;
    opacity: 1;
  }

  &.is-indent-1 {
    transform: none;
    opacity: 1;
  }

  &.is-indent-2 {
    transform: none;
    opacity: 1;
  }
}


// Indicators

.features-indicators {
  position: relative;
  z-index: 20;
}

.features-indicators ul {
  @include m-list-unstyled;
  text-align: center;
  margin-bottom: 0;
}

.features-indicators li {
  display: inline-block;
}

.features-indicators button {
  background: rgba(black, 0.3);
  border: 0;
  font-size: 0;
  padding: 0;
  margin: 0 2px;
  height: 3px;
  width: 18px;
  &:focus {
    background: rgba(black, 0.75);
    outline: none;
  }
}
.features-indicators .slick-active button {
  background-color: black;
}

// Features Background

.features-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}

.features-background div {
  height: 100%;
}

.features-background img {
  max-width: 100%;
  display: block;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

/* Location Section
/* -------------------------------------------------------------------------- */
.home-location {
  border-top: 1px solid rgba(white, 0.05);
  padding-top: $spacing-6 * 2;
  padding-bottom: $spacing-6 * 2;
  color: white;
  background-color: $color-primary;
}

.pattern-left,
.pattern-right {
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  z-index: 0;
}

.pattern-left {
  width: 100%;
  height: 300px;
  background-image: url('../img/home/pattern-left-01.jpg');
  top: 0;
  left: 0;

  @include on-tablet {
    width: 650px;
    height: 620px;
  }
}
.pattern-right {
  width: 100%;
  height: 200px;
  background-image: url('../img/home/pattern-right-01.jpg');
  background-position-x: 100%;
  top: 40%;
  right: 0;

  @include on-tablet {
    width: 420px;
    height: 420px;
  }
}

/* Registration Section
/* -------------------------------------------------------------------------- */
.home-registration {
  border-top: 1px solid rgba(white, 0.05);
  padding-top: $spacing-6 * 2;
  padding-bottom: $spacing-6 * 2;
  color: white;
}

.home-registration .form-check-label {
  color: white;
  text-transform: none;
  font-size: 14px;
  
  @include on-tablet {
    font-size: 12px;
  }
}

.home-registration hr {
  background-color: rgba($color-secondary, 0.3);
}

.home-registration-tnc .form-check {
  padding-left: 2em;
}

.home-registration-tnc .form-check-input {
  margin-left: -2em;
}

.home-registration-tnc em {
  display: block;
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  color: $color-secondary;
  letter-spacing: 1px;
}

.home-registration-tnc .form-check-label {
  position: relative;
  top: 5px;
}

.home-registration .accordion {
  margin-top: 1.5rem;
}

.home-registration .accordion .btn {
  width: 100%;
  color: $color-secondary;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    color: white;
    text-decoration: none;
  }
  &:focus {
    text-decoration: none;
  }
}

.home-registration .accordion .btn .badge {
  background-color: $color-secondary-d2;
  padding: 0.3em 0.75em;
}

.home-registration .accordion .card {
  background-color: transparent;
  border: 1px solid rgba($color-secondary-d2, 0.6)
}
.home-registration .accordion .card:first-of-type,
.home-registration .accordion .card-header {
  border-bottom: 1px solid rgba($color-secondary-d2, 0.6);
}

.accordian-button {
  letter-spacing: 0.2em;
}

.accordion .card {
  .is-open {
    display: block;
  }
  .is-close {
    display: none;
  }
}
.accordion .card .collapsed {
  .is-open {
    display: none;
  }
  .is-close {
    display: block;
  }
}

// QapTcha overrides
.home-registration .QapTcha {
  margin: 0 auto;
  @include on-tablet {
    margin: 0;
  }
}

.home-registration .QapTcha .bgSlider {
  border-radius: 0;
  background: none;
  background-color: white;
  position: relative;
  height: 48px;
}

.home-registration .QapTcha .bgSlider:after {
  content: 'Slide to Submit';
  display: block;
  color: $color-text;
  position: absolute;
  top: 50%;
  left: 48px;
  right: 0;
  transform: translateY(-50%);
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
}

.site-cn .home-registration .QapTcha .bgSlider:after {
  content: '请滑动提交';
}

.home-registration .QapTcha .Slider {
  background: $color-secondary;
  width: 60px;
  top: 2px;
  left: 2px;
  height: 42px;
  z-index: 90;
}

.home-registration .QapTcha .Slider:after {
  content: '›';
  position: absolute;
  top: calc(50% - 3px);
  left: 50%;
  font-size: 24px;
  color: white;
  font-weight: bold;
  transform: translate(-50%, -50%);
}