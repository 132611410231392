/**
 * @base Responsive definitions
 */

$breakpoint-xs: 375px;
$breakpoint-sm: 768px;
$breakpoint-md: 992px;
$breakpoint-lg: 1200px;
$breakpoint-xlg: 1600px;

@mixin on-mobile-lg {
  @media screen and (min-width: $breakpoint-xs) { @content; }
}

@mixin until-mobile {
  @media screen and (max-width: $breakpoint-sm - 1) { @content; }
}

@mixin on-tablet {
  @media screen and (min-width: $breakpoint-sm) { @content; }
}

@mixin until-tablet {
  @media screen and (max-width: $breakpoint-md - 1) { @content; }
}

@mixin on-desktop {
  @media screen and (min-width: $breakpoint-md) { @content; }
}

@mixin until-desktop {
  @media screen and (max-width: $breakpoint-lg - 1) { @content; }
}

@mixin on-desktop-lg {
  @media screen and (min-width: $breakpoint-lg) { @content; }
}

@mixin until-desktop-lg {
  @media screen and (max-width: $breakpoint-xlg - 1) { @content; }
}

@mixin on-desktop-xlg {
  @media screen and (min-width: $breakpoint-xlg) { @content; }
}
