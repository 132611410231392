/**
 * @page Contact
 */

.contact {
  color: rgba(white, 0.8);
  padding-bottom: $spacing-6 * 2;
  text-align: center;
  background-image: url('../img/contact/background.jpg');
  background-size: cover;

  @include on-tablet {
    text-align: left;
  }
}

.contact h6 {
  color: white;
  font-weight: bold;
}

.contact a {
  color: white;
  text-decoration: underline;
}

.contact-map iframe {
  width: 100%;
  min-height: 450px;
}