/**
 * @component Footer
 */

.footer {
  background-color: white;
  text-align: center;
  font-size: 16px;
  display: flex;
  align-items: center;
  padding: $spacing-6 0;

  @include on-tablet {
    height: 70px;
    font-size: 14px;
    padding: 0;
  }
}

.footer a {
  color: $color-text;
}
