@charset "UTF-8";
/**
 * Pavilion Genting Highlands Microsite
 *
 * @author James Ooi <james.ooi@forefront.com.my>
 * @designer Ivy Sho <ivy.sho@forefront.com.my>
 */
/* Vendor Imports
/* -------------------------------------------------------------------------- */
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Font Imports
/* -------------------------------------------------------------------------- */
/**
 * @fonts Petita
 */
@font-face {
  font-family: 'Petita';
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  src: url("../fonts/petita/PetitaBold-Regular.woff2") format("woff2"), url("../fonts/petita/PetitaBold-Regular.woff") format("woff"), url("../fonts/petita/PetitaBold-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'Petita';
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  src: url("../fonts/petita/PetitaMedium-Regular.woff2") format("woff2"), url("../fonts/petita/PetitaMedium-Regular.woff") format("woff"), url("../fonts/petita/PetitaMedium-Regular.ttf") format("truetype"); }

/**
 * @fonts Bauer Bodoni
 */
@font-face {
  font-family: 'Bauer Bodoni';
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  src: url("../fonts/bauer-bodoni/BauerBodoni-BT-Regular.woff2") format("woff2"), url("../fonts/bauer-bodoni/BauerBodoni-BT-Regular.woff") format("woff"), url("../fonts/bauer-bodoni/BauerBodoni-BT-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'Bauer Bodoni';
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  src: url("../fonts/bauer-bodoni/BauerBodoni-BT-Bold.woff2") format("woff2"), url("../fonts/bauer-bodoni/BauerBodoni-BT-Bold.woff") format("woff"), url("../fonts/bauer-bodoni/BauerBodoni-BT-Bold.ttf") format("truetype"); }

/* Base Imports
/* -------------------------------------------------------------------------- */
/**
 * @base Responsive definitions
 */
/**
 * @base Color definitions
 */
/* Primary Color
/* -------------------------------------------------------------------------- */
/* Secondary Color
/* -------------------------------------------------------------------------- */
/* Text Color
/* -------------------------------------------------------------------------- */
/**
 * @base Typography definitions
 */
html, body {
  font-size: 16px;
  font-family: "Petita", "Montserrat", sans-serif;
  line-height: 1.4; }

a {
  color: #d5c69b; }

a:hover {
  color: #e4dabf; }

::selection {
  background-color: rgba(213, 198, 155, 0.3); }

/**
 * @base Mixin definitions
 */
/**
 * @base Layout definitions
 */
body {
  background-color: #513f2f; }

/* Spacing Presets
/* -------------------------------------------------------------------------- */
/**
 * @base Utility definitions
 */
.-debug,
.-debug * {
  border: 1px solid red; }

/* Component Imports
/* -------------------------------------------------------------------------- */
/**
 * @component Navigation component
 */
/* Main Navigation
/* -------------------------------------------------------------------------- */
.navigation-container {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 90;
  background-color: rgba(81, 63, 47, 0.7);
  height: 100px;
  display: flex;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(4px); }

body[data-page=home] .navigation-container {
  border-bottom: 0;
  backdrop-filter: none;
  background-color: transparent; }

.navigation-logo {
  width: 180px; }

body[data-page=home] .navigation-logo {
  display: none; }

.navigation-logo img {
  display: block; }

.navigation-menu {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-bottom: 0; }

.navigation-menu li {
  margin: 0 0.5rem;
  transition: 0.7s cubic-bezier(0.165, 0.84, 0.44, 1) all; }

body.is-loading .navigation-menu li {
  transform: translateY(-15px);
  opacity: 0; }

.navigation-menu li:nth-of-type(1) {
  transition-delay: 0.4s; }

.navigation-menu li:nth-of-type(2) {
  transition-delay: 0.5s; }

.navigation-menu li:nth-of-type(3) {
  transition-delay: 0.6s; }

.navigation-menu li:nth-of-type(4) {
  transition-delay: 0.7s; }

.navigation-menu li:nth-of-type(5) {
  transition-delay: 0.8s; }

.navigation-menu li:nth-of-type(6) {
  transition-delay: 0.9s; }

.navigation-menu li a {
  display: block;
  padding: 1.5rem 0.75rem;
  color: rgba(255, 255, 255, 0.9);
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 13px; }

.navigation-menu li a:hover {
  text-decoration: none;
  color: white; }

.navigation-menu li a:focus {
  outline: 3px solid rgba(213, 198, 155, 0.2); }

.navigation-menu li.is-cta a {
  background-color: #c6b277;
  padding: 0.75rem 1.5rem; }

.navigation-menu li.is-cta a:hover {
  background-color: #bea765; }

/* Mobile Navigation
/* -------------------------------------------------------------------------- */
.navigation-mobile-container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 100; }

.navigation-mobile-bar {
  position: fixed;
  z-index: 110;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
  display: flex; }

.navigation-mobile-bar-hamburger {
  -webkit-appearance: none;
  position: relative;
  margin-left: auto;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  width: 48px;
  height: 48px;
  border: 0; }

.navigation-mobile-bar-hamburger.is-active {
  box-shadow: none;
  background-color: transparent; }

.navigation-mobile-bar-hamburger .icon {
  width: 20px;
  height: 20px;
  fill: #a99047;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.navigation-mobile-bar-hamburger .icon-close {
  display: none; }

.navigation-mobile-bar-hamburger.is-active .icon-close {
  display: block; }

.navigation-mobile-bar-hamburger.is-active .icon-hamburger {
  display: none; }

.navigation-mobile-menu-container {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #d5c69b;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  pointer-events: none;
  transform: translateX(100%);
  transition: 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) all; }

.navigation-mobile-menu-container.is-active {
  visibility: visible;
  pointer-events: all;
  opacity: 1;
  transform: none; }

.navigation-mobile-menu {
  list-style-type: none;
  padding-left: 0;
  width: 100%;
  text-align: center; }

.navigation-mobile-menu li a {
  display: block;
  width: 100%;
  padding: 1rem 0.25rem;
  color: #715842;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-weight: bold;
  font-family: "Bauer Bodoni", "Didot", sans-serif;
  font-size: 24px; }

/* Fixed Navigation
/* -------------------------------------------------------------------------- */
.navigation-fixed-container {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  background-color: #513f2f;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  transition: 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) all;
  transform: translateY(-100%);
  visibility: hidden; }

.navigation-fixed-container.is-active {
  transform: none;
  visibility: visible; }

.navigation-fixed-menu {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  justify-content: center; }

.navigation-fixed-menu li {
  margin: 0 0.5rem; }

.navigation-fixed-menu li a {
  display: block;
  padding: 1.5rem 0.75rem;
  color: rgba(255, 255, 255, 0.9);
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 13px; }

.navigation-fixed-menu li a:hover {
  text-decoration: none;
  color: white; }

.navigation-fixed-menu li a:focus {
  outline: 3px solid rgba(213, 198, 155, 0.2); }

.navigation-fixed-menu li.is-cta a {
  background-color: #c6b277;
  padding: 0.75rem 1.5rem; }

.navigation-fixed-menu li.is-cta a:hover {
  background-color: #bea765; }

/**
 * @component Icon component
 */
.icon {
  width: 32px;
  height: 32px; }

.icon.icon-small {
  width: 16px;
  height: 16px; }

.icon.icon-white {
  fill: white; }

button.close {
  position: absolute;
  top: 20px;
  right: 20px;
  opacity: 0.8;
  z-index: 1;
  pointer-events: auto;
  transition: opacity 0.3s ease; }
  button.close:hover {
    opacity: 1 !important; }

.icon-close {
  width: 40px;
  height: 40px;
  background-color: #bea765;
  border: 1px solid white;
  border-radius: 50%;
  position: relative;
  display: inline-block;
  opacity: 1; }
  .icon-close i:before,
  .icon-close i:after {
    content: "";
    width: 20px;
    height: 1px;
    background-color: white;
    position: absolute;
    top: 50%; }
  .icon-close i:before {
    left: 50%;
    transform: translateX(-50%) rotate(45deg); }
  .icon-close i:after {
    left: 50%;
    transform: translateX(-50%) rotate(-45deg); }

.close-CNY {
  background-color: #c49926;
  display: inline-block;
  width: 20px;
  height: 20px;
  overflow: hidden;
  position: relative;
  z-index: 1; }

.close-CNY:hover {
  cursor: pointer; }

.close-CNY:hover i::before,
.close-CNY:hover i::after {
  background: #c4dde2; }

.close-CNY i::before,
.close-CNY i::after {
  content: '';
  position: absolute;
  height: 1px;
  width: 100%;
  top: 50%;
  left: 0;
  margin-top: -1px;
  background: white; }

.close-CNY i::before {
  transform: rotate(45deg); }

.close-CNY i::after {
  transform: rotate(-45deg); }

.close-CNY.pointy i::before,
.close-CNY.pointy i::after {
  width: 200%;
  left: -50%; }

/**
 * @component Form definitions
 */
/* Label elements
/* -------------------------------------------------------------------------- */
label {
  color: #d5c69b;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 1px; }
  @media screen and (min-width: 768px) {
    label {
      font-size: 12px; } }

/* Input elements
/* -------------------------------------------------------------------------- */
.form-control {
  border-radius: 0;
  border: 2px solid #c6b277; }

.form-control:focus {
  border: 2px solid white;
  box-shadow: 0 0 0 0.2rem rgba(213, 198, 155, 0.25); }

/* Custom <select>
/* -------------------------------------------------------------------------- */
.select-control {
  position: relative; }

.select-control select {
  border-radius: 0;
  -webkit-appearance: none; }

.select-control:after {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  position: absolute;
  top: calc(50% - 2.5px);
  right: 12px;
  border: solid #c6b277;
  border-width: 0 2px 2px 0;
  transform: translateY(-50%) rotate(45deg); }

/* Checkbox Group
/* -------------------------------------------------------------------------- */
.checkbox-group {
  min-height: 38px;
  display: flex;
  flex-wrap: wrap; }

.checkbox-group label {
  text-transform: none;
  color: white; }

/**
 * @component Buttons
 */
.btn {
  border-radius: 0;
  padding: 0.5rem 1.5rem; }

.btn-primary {
  background-color: #c6b277;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 14px; }

.btn-primary:hover {
  background-color: #bea765; }

.btn-primary:focus,
.btn-primary:active:focus {
  outline: 3px solid rgba(213, 198, 155, 0.3);
  box-shadow: none; }

.btn-primary:active {
  background-color: #bea765 !important; }

.btn-toggle.btn-sm {
  margin: 0 0.5rem;
  padding: 0;
  position: relative;
  border: none;
  height: 2rem;
  width: 4rem;
  border-radius: 2rem;
  color: #6b7381;
  background: #c6b277; }
  .btn-toggle.btn-sm:focus, .btn-toggle.btn-sm:focus.active, .btn-toggle.btn-sm.focus, .btn-toggle.btn-sm.focus.active {
    outline: none; }
  .btn-toggle.btn-sm .btn-language {
    font-weight: 600;
    font-size: 0.55rem;
    color: white;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 2rem;
    display: inline-block;
    padding: 0;
    position: absolute;
    bottom: 0;
    z-index: 1;
    transition: opacity .25s; }
    .btn-toggle.btn-sm .btn-language:focus {
      outline: none; }
  .btn-toggle.btn-sm .btn-language.cn {
    right: 0.5rem; }
  .btn-toggle.btn-sm .btn-language.en {
    left: 0.5rem; }
  .btn-toggle.btn-sm > .handle {
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 1.5rem;
    background: #fff;
    transition: left .25s; }
  .btn-toggle.btn-sm .btn-language.cn.active {
    color: #6b7381; }
  .btn-toggle.btn-sm .btn-language.cn.active ~ .handle {
    left: 2.25rem; }
  .btn-toggle.btn-sm .btn-language.en.active {
    color: #6b7381; }
  .btn-toggle.btn-sm .btn-language.en.active ~ .handle {
    left: 0.25rem; }
  .btn-toggle.btn-sm.active {
    background-color: #c6b277; }

/**
 * @component Section component
 */
.section.is-first {
  margin-top: 100px;
  padding-top: 3rem; }

.section.is-full-height {
  min-height: calc(100vh - 170px);
  display: flex;
  align-items: center; }

.section-header {
  max-width: 500px;
  margin: 0 auto 3rem;
  text-align: center;
  position: relative;
  z-index: 10; }

.section-header__title {
  font-size: 42px;
  font-family: "Bauer Bodoni", "Didot", sans-serif;
  color: #d5c69b;
  text-transform: uppercase;
  letter-spacing: 6px; }

.section-header__subtitle {
  color: #f3efe3;
  font-size: 14px;
  line-height: 2; }

/**
 * @component Footer
 */
.footer {
  background-color: white;
  text-align: center;
  font-size: 16px;
  display: flex;
  align-items: center;
  padding: 3rem 0; }
  @media screen and (min-width: 768px) {
    .footer {
      height: 70px;
      font-size: 14px;
      padding: 0; } }

.footer a {
  color: #282828; }

/**
 * @component Modal
 */
.modal {
  -webkit-overflow-scrolling: touch; }

.modal.modal-slide-up .modal-dialog {
  opacity: 0;
  transform: translateY(30px);
  transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) all; }

.modal.modal-slide-up.show .modal-dialog {
  opacity: 1;
  transform: none; }

ol.list-sectioned {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0; }

ol.list-sectioned > li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em; }

ol.list-sectioned > li:before {
  content: attr(data-section) "." counter(item);
  display: table-cell;
  padding-right: 0.6em; }

/* Article Modal
/* -------------------------------------------------------------------------- */
.article-modal .modal-content {
  border-radius: 0;
  background-color: #e4dabf;
  border: none; }

.article-modal .modal-body {
  padding: 3rem 1.5rem 1.5rem; }
  @media screen and (min-width: 768px) {
    .article-modal .modal-body {
      padding: 3rem; } }

.article-modal .close {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem; }
  .article-modal .close:focus {
    outline: 3px solid #c6b277; }

.article-modal ::selection {
  background-color: rgba(173, 216, 230, 0.6); }

.article-modal {
  font-size: 14px; }

.article-modal a {
  color: #927154; }

.article-modal h1 {
  font-family: "Bauer Bodoni", "Didot", sans-serif;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 3px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 1rem; }

.article-modal h2 {
  font-weight: bold;
  font-size: 20px; }

.article-modal .table {
  border: 1px solid #282828; }

.article-modal .table td {
  border-top-color: #282828; }

/* Img Modal
/* -------------------------------------------------------------------------- */
.img-modal .modal-content {
  background-color: transparent;
  border: 0; }

.img-modal .modal-body {
  padding: 0; }

.img-modal .close {
  background: #b88550;
  color: rgba(0, 0, 0, 0.8);
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  padding: 10px;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 1; }
  .img-modal .close:hover {
    opacity: 1 !important; }

/* Custom Modal - Season
/* -------------------------------------------------------------------------- */
@media (min-width: 576px) {
  .modal-season .modal-dialog {
    max-width: 800px; } }

@media (max-height: 700px) and (orientation: landscape) {
  .modal-season .modal-dialog {
    max-height: calc(100vh - 3.5rem); }
  .modal-season .modal-content {
    background-color: transparent;
    border: none; }
  .modal-season img {
    max-height: 100vh;
    display: block;
    margin: auto; } }

/* Page Imports
/* -------------------------------------------------------------------------- */
/**
 * @page Homepage
 */
body.is-loading {
  max-height: 100vh;
  overflow: hidden; }

body[data-page=home]:before {
  content: '';
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #513f2f;
  pointer-events: none;
  z-index: 95;
  transition: 0.6s linear all; }

body.is-loaded:before {
  opacity: 0; }

.home-main {
  background-color: #513f2f;
  position: relative; }

.home-main > * {
  position: relative; }

.home-main-backgrounds {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  opacity: 0;
  transition: 1.2s linear all; }

.home-main-backgrounds.is-loaded {
  opacity: 1; }

.home-main-backgrounds video {
  position: absolute;
  top: 0;
  width: 100vw; }

/* Hero
/* -------------------------------------------------------------------------- */
.home-hero > .container {
  min-height: 85vh;
  padding-top: 3rem;
  padding-bottom: 3rem; }
  @media screen and (min-width: 768px) {
    .home-hero > .container {
      min-height: 100vh; } }

.home-hero-header {
  height: 100%;
  flex-grow: 1; }

.home-hero-footer {
  opacity: 0;
  transition: 1.2s linear all; }

.home-hero-footer.is-loaded {
  opacity: 1; }

.home-hero-footer a:focus {
  outline: 3px solid rgba(213, 198, 155, 0.2); }

.home-hero-footer .icon {
  width: 24px;
  height: 24px;
  fill: #d5c69b;
  filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.5));
  animation: 1.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) hero-chevron-bob infinite; }

@keyframes hero-chevron-bob {
  0% {
    transform: translateY(0px); }
  50% {
    transform: translateY(-10px); }
  100% {
    transform: translateY(0px); } }

/* Masthead
/* -------------------------------------------------------------------------- */
.masthead {
  transform: scale(0.95);
  transition: 2s cubic-bezier(0.165, 0.84, 0.44, 1) all;
  position: relative;
  z-index: 99; }

.masthead > span {
  width: 250px;
  position: relative;
  display: block; }
  @media screen and (min-width: 375px) {
    .masthead > span {
      width: 280px; } }
  @media screen and (min-width: 768px) {
    .masthead > span {
      width: 400px; } }

.masthead > span:nth-of-type(1) {
  overflow: hidden; }

.masthead > span:nth-of-type(3) {
  overflow: hidden; }

.masthead img {
  max-width: 100%;
  display: block;
  transition: 2s cubic-bezier(0.075, 0.82, 0.165, 1) all; }

.masthead > span:nth-of-type(1) > img {
  transform: translateY(100%);
  opacity: 0; }

.masthead > span:nth-of-type(2) > img {
  transform: scaleX(0); }

.masthead > span:nth-of-type(3) > img {
  transform: translateY(-100%);
  opacity: 0; }

.masthead .masthead-glow {
  position: absolute;
  width: 3px;
  height: 3px;
  top: 50%;
  left: 87%;
  transform: translateY(-50%);
  opacity: 0; }

.masthead .masthead-glow:after {
  content: '';
  position: absolute;
  display: block;
  background: url("../img/masthead/masthead-400px-glow.png");
  background-size: 100% 100%;
  width: 110px;
  height: 110px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.masthead.is-loading > span:nth-of-type(2) > img {
  transition: 9s cubic-bezier(0.165, 0.84, 0.44, 1) all;
  transform: scaleX(0.6); }

.masthead.is-loaded {
  transform: scale(1); }
  .masthead.is-loaded > span:nth-of-type(1) > img {
    transform: none;
    opacity: 1;
    transition-delay: 1s; }
  .masthead.is-loaded > span:nth-of-type(2) > img {
    transform: scaleX(1);
    transition: 2s cubic-bezier(0.165, 0.84, 0.44, 1) all; }
  .masthead.is-loaded > span:nth-of-type(3) > img {
    transform: none;
    opacity: 1;
    transition-delay: 1s; }
  .masthead.is-loaded .masthead-glow {
    animation: 1s cubic-bezier(0.785, 0.135, 0.15, 0.86) masthead-glow-animation forwards;
    animation-delay: 1s; }

@keyframes masthead-glow-animation {
  0% {
    left: 10%;
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    left: 90%;
    opacity: 0; } }

/* Feature Section
/* -------------------------------------------------------------------------- */
.home-features {
  padding-top: 6rem;
  padding-bottom: 6rem;
  overflow: hidden;
  background-color: #513f2f; }
  @media screen and (min-width: 768px) {
    .home-features {
      min-height: 100vh;
      display: flex;
      flex-direction: column; } }

.features-body {
  margin-top: auto; }

.features-body p,
.feature-description {
  color: white;
  font-size: 14px;
  letter-spacing: 1px;
  max-width: 480px;
  margin: 0 auto;
  text-align: center;
  line-height: 1.6;
  position: relative;
  z-index: 20;
  text-shadow: 0 0 12px black; }
  @media screen and (min-width: 768px) {
    .features-body p,
    .feature-description {
      font-size: 16px;
      text-shadow: 0 0 15px black;
      line-height: 2; } }
  @media screen and (min-width: 1600px) {
    .features-body p,
    .feature-description {
      max-width: 540px;
      font-size: 18px; } }
  .features-body p::selection,
  .feature-description::selection {
    background-color: #d5c69b; }

.features-carousel {
  position: relative;
  z-index: 20;
  padding: 1rem;
  pointer-events: none; }
  @media screen and (min-width: 768px) {
    .features-carousel {
      padding: 0;
      height: 100%;
      flex-grow: 1;
      display: flex;
      align-items: center; } }

.feature-item {
  position: relative;
  text-align: center;
  transition: 1.2s linear all; }
  @media screen and (min-width: 768px) {
    .feature-item {
      opacity: 0; } }

.slick-current .feature-item {
  opacity: 1; }

.features-carousel-controls {
  position: relative;
  height: 48px;
  margin-bottom: 3rem; }
  @media screen and (min-width: 768px) {
    .features-carousel-controls {
      position: static;
      margin-bottom: 0;
      height: auto; } }

.features-carousel-controls__button {
  background-color: #513f2f;
  border: 0;
  fill: white;
  padding: 1rem;
  width: 48px;
  height: 48px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 20; }
  .features-carousel-controls__button:focus {
    outline: 3px solid rgba(81, 63, 47, 0.5); }

.features-carousel-controls__button .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 18px;
  height: 18px; }

.features-carousel-controls__button.is-prev {
  left: 0; }

.features-carousel-controls__button.is-next {
  right: 0; }

.feature-title {
  font-family: "Bauer Bodoni", "Didot", sans-serif;
  font-size: 28px;
  text-transform: uppercase;
  letter-spacing: 3px;
  margin: 0 auto 3rem;
  display: inline-block;
  text-align: center; }
  @media screen and (min-width: 768px) {
    .feature-title {
      text-align: left;
      font-size: 42px;
      letter-spacing: 6px;
      margin-bottom: 6rem; } }

.feature-title-line {
  color: #513f2f;
  display: inline-block;
  opacity: 0;
  transition: 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) all; }

.feature-title-line.is-colored {
  transform: translateY(2em);
  font-size: 1.2em;
  color: #513f2f; }

.feature-title-line.is-indent-1 {
  transform: translateY(2em);
  transition-delay: 0.4s; }
  @media screen and (min-width: 768px) {
    .feature-title-line.is-indent-1 {
      margin-left: 4rem; } }

.feature-title-line.is-indent-2 {
  transform: translateY(3em);
  transition-delay: 0.8s; }
  @media screen and (min-width: 768px) {
    .feature-title-line.is-indent-2 {
      margin-left: 8rem; } }

.feature-title-line.is-underlined {
  position: relative; }
  .feature-title-line.is-underlined:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -0.75rem;
    height: 2px;
    background-color: #513f2f; }
    @media screen and (min-width: 768px) {
      .feature-title-line.is-underlined:after {
        left: 2rem;
        right: 3rem; } }

.feature-title-line small {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1.5rem;
  transform: translateY(1em);
  text-align: center;
  font-size: 12px;
  font-family: "Petita", "Montserrat", sans-serif;
  font-weight: normal;
  letter-spacing: 1px; }
  @media screen and (min-width: 768px) {
    .feature-title-line small {
      left: 2rem;
      right: 3em;
      font-size: 16px;
      letter-spacing: 2px; } }

.feature-title-line.animate.is-colored {
  transform: none;
  opacity: 1; }

.feature-title-line.animate.is-indent-1 {
  transform: none;
  opacity: 1; }

.feature-title-line.animate.is-indent-2 {
  transform: none;
  opacity: 1; }

.features-indicators {
  position: relative;
  z-index: 20; }

.features-indicators ul {
  list-style-type: none;
  padding-left: 0;
  text-align: center;
  margin-bottom: 0; }

.features-indicators li {
  display: inline-block; }

.features-indicators button {
  background: rgba(0, 0, 0, 0.3);
  border: 0;
  font-size: 0;
  padding: 0;
  margin: 0 2px;
  height: 3px;
  width: 18px; }
  .features-indicators button:focus {
    background: rgba(0, 0, 0, 0.75);
    outline: none; }

.features-indicators .slick-active button {
  background-color: black; }

.features-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10; }

.features-background div {
  height: 100%; }

.features-background img {
  max-width: 100%;
  display: block;
  object-fit: cover;
  height: 100%;
  width: 100%; }

/* Location Section
/* -------------------------------------------------------------------------- */
.home-location {
  border-top: 1px solid rgba(255, 255, 255, 0.05);
  padding-top: 6rem;
  padding-bottom: 6rem;
  color: white;
  background-color: #513f2f; }

.pattern-left,
.pattern-right {
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  z-index: 0; }

.pattern-left {
  width: 100%;
  height: 300px;
  background-image: url("../img/home/pattern-left-01.jpg");
  top: 0;
  left: 0; }
  @media screen and (min-width: 768px) {
    .pattern-left {
      width: 650px;
      height: 620px; } }

.pattern-right {
  width: 100%;
  height: 200px;
  background-image: url("../img/home/pattern-right-01.jpg");
  background-position-x: 100%;
  top: 40%;
  right: 0; }
  @media screen and (min-width: 768px) {
    .pattern-right {
      width: 420px;
      height: 420px; } }

/* Registration Section
/* -------------------------------------------------------------------------- */
.home-registration {
  border-top: 1px solid rgba(255, 255, 255, 0.05);
  padding-top: 6rem;
  padding-bottom: 6rem;
  color: white; }

.home-registration .form-check-label {
  color: white;
  text-transform: none;
  font-size: 14px; }
  @media screen and (min-width: 768px) {
    .home-registration .form-check-label {
      font-size: 12px; } }

.home-registration hr {
  background-color: rgba(213, 198, 155, 0.3); }

.home-registration-tnc .form-check {
  padding-left: 2em; }

.home-registration-tnc .form-check-input {
  margin-left: -2em; }

.home-registration-tnc em {
  display: block;
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  color: #d5c69b;
  letter-spacing: 1px; }

.home-registration-tnc .form-check-label {
  position: relative;
  top: 5px; }

.home-registration .accordion {
  margin-top: 1.5rem; }

.home-registration .accordion .btn {
  width: 100%;
  color: #d5c69b;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .home-registration .accordion .btn:hover {
    color: white;
    text-decoration: none; }
  .home-registration .accordion .btn:focus {
    text-decoration: none; }

.home-registration .accordion .btn .badge {
  background-color: #c6b277;
  padding: 0.3em 0.75em; }

.home-registration .accordion .card {
  background-color: transparent;
  border: 1px solid rgba(198, 178, 119, 0.6); }

.home-registration .accordion .card:first-of-type,
.home-registration .accordion .card-header {
  border-bottom: 1px solid rgba(198, 178, 119, 0.6); }

.accordian-button {
  letter-spacing: 0.2em; }

.accordion .card .is-open {
  display: block; }

.accordion .card .is-close {
  display: none; }

.accordion .card .collapsed .is-open {
  display: none; }

.accordion .card .collapsed .is-close {
  display: block; }

.home-registration .QapTcha {
  margin: 0 auto; }
  @media screen and (min-width: 768px) {
    .home-registration .QapTcha {
      margin: 0; } }

.home-registration .QapTcha .bgSlider {
  border-radius: 0;
  background: none;
  background-color: white;
  position: relative;
  height: 48px; }

.home-registration .QapTcha .bgSlider:after {
  content: 'Slide to Submit';
  display: block;
  color: #282828;
  position: absolute;
  top: 50%;
  left: 48px;
  right: 0;
  transform: translateY(-50%);
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  white-space: nowrap; }

.site-cn .home-registration .QapTcha .bgSlider:after {
  content: '请滑动提交'; }

.home-registration .QapTcha .Slider {
  background: #d5c69b;
  width: 60px;
  top: 2px;
  left: 2px;
  height: 42px;
  z-index: 90; }

.home-registration .QapTcha .Slider:after {
  content: '›';
  position: absolute;
  top: calc(50% - 3px);
  left: 50%;
  font-size: 24px;
  color: white;
  font-weight: bold;
  transform: translate(-50%, -50%); }

/**
 * @page Contact
 */
.contact {
  color: rgba(255, 255, 255, 0.8);
  padding-bottom: 6rem;
  text-align: center;
  background-image: url("../img/contact/background.jpg");
  background-size: cover; }
  @media screen and (min-width: 768px) {
    .contact {
      text-align: left; } }

.contact h6 {
  color: white;
  font-weight: bold; }

.contact a {
  color: white;
  text-decoration: underline; }

.contact-map iframe {
  width: 100%;
  min-height: 450px; }

/**
 * @page 404 Page
 */
.page-not-found {
  min-height: calc(100vh - 170px);
  display: flex;
  align-items: center; }
