/**
 * @component Modal
 */

.modal {
  -webkit-overflow-scrolling: touch;
}

.modal.modal-slide-up {
}

.modal.modal-slide-up .modal-dialog {
  opacity: 0;
  transform: translateY(30px);
  transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) all;
}

.modal.modal-slide-up.show .modal-dialog {
  opacity: 1;
  transform: none;
}

ol.list-sectioned {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
}

ol.list-sectioned > li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
}

ol.list-sectioned > li:before {
  content: attr(data-section) "." counter(item);
  display: table-cell;
  padding-right: 0.6em;
}

/* Article Modal
/* -------------------------------------------------------------------------- */
.article-modal .modal-content {
  border-radius: 0;
  background-color: $color-secondary-l1;
  border: none;
}

.article-modal .modal-body {
  padding: $spacing-6 $spacing-5 $spacing-5;
  @include on-tablet {
    padding: $spacing-6;
  }
}

.article-modal .close {
  position: absolute;
  top: $spacing-5;
  right: $spacing-5;

  &:focus {
    outline: 3px solid $color-secondary-d2;
  }
}

.article-modal ::selection {
  background-color: rgba(lightblue, 0.6);
}

.article-modal {
  font-size: 14px;
}

.article-modal a {
  color: $color-primary-l2;
}

.article-modal h1 {
  font-family: $typeface-secondary;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 3px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: $spacing-4;
}

.article-modal h2 {
  font-weight: bold;
  font-size: 20px;
}

.article-modal .table {
  border: 1px solid $color-text;
}

.article-modal .table td {
  border-top-color: $color-text;
}

/* Img Modal
/* -------------------------------------------------------------------------- */
.img-modal .modal-content {
  background-color: transparent;
  border: 0;
}

.img-modal .modal-body {
  padding: 0;
}

.img-modal .close {
  background: #b88550;
  color: rgba(black, 0.8);
  text-shadow: 0 1px 0 rgba(white, 0.5);
  padding: 10px;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 1;

  &:hover {
    opacity: 1 !important;
  }
}

/* Custom Modal - Season
/* -------------------------------------------------------------------------- */
@media (min-width: 576px) {
  .modal-season .modal-dialog {
    max-width: 800px;
  }
}

@media (max-height: 700px) and (orientation : landscape) {
  .modal-season .modal-dialog {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-season .modal-content {
    background-color: transparent;
    border: none;
  }

  .modal-season img {
    max-height: 100vh;
    display: block;
    margin: auto;
  }
}