/**
 * @fonts Petita
 */

@font-face {
  font-family: 'Petita';
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  src: url('../fonts/petita/PetitaBold-Regular.woff2') format('woff2'),
       url('../fonts/petita/PetitaBold-Regular.woff') format('woff'),
       url('../fonts/petita/PetitaBold-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Petita';
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  src: url('../fonts/petita/PetitaMedium-Regular.woff2') format('woff2'),
       url('../fonts/petita/PetitaMedium-Regular.woff') format('woff'),
       url('../fonts/petita/PetitaMedium-Regular.ttf') format('truetype');
}
