/**
 * Pavilion Genting Highlands Microsite
 *
 * @author James Ooi <james.ooi@forefront.com.my>
 * @designer Ivy Sho <ivy.sho@forefront.com.my>
 */


/* Vendor Imports
/* -------------------------------------------------------------------------- */
@import '../../node_modules/slick-carousel/slick/slick.scss';

/* Font Imports
/* -------------------------------------------------------------------------- */
@import 'fonts/petita';
@import 'fonts/bauer-bodoni';

/* Base Imports
/* -------------------------------------------------------------------------- */
@import 'base/responsive';
@import 'base/colors';
@import 'base/typography';
@import 'base/mixins';
@import 'base/layout';
@import 'base/utilities';

/* Component Imports
/* -------------------------------------------------------------------------- */
@import 'components/navigation';
@import 'components/icon';
@import 'components/forms';
@import 'components/buttons';
@import 'components/toggle-button';
@import 'components/section';
@import 'components/footer';
@import 'components/modal';

/* Page Imports
/* -------------------------------------------------------------------------- */
@import 'pages/home';
@import 'pages/contact';
@import 'pages/404';
