/**
 * @component Navigation component
 */

/* Main Navigation
/* -------------------------------------------------------------------------- */
.navigation-container {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 90;
  background-color: rgba($color-primary, 0.7);
  height: 100px;
  display: flex;
  align-items: center;
  padding: $spacing-4;
  border-bottom: 1px solid rgba(white, 0.1);
  backdrop-filter: blur(4px);
}

body[data-page=home] .navigation-container {
  border-bottom: 0;
  backdrop-filter: none;
  background-color: transparent;
}

.navigation-logo {
  width: 180px;
}

body[data-page=home] .navigation-logo {
  display: none;
}

.navigation-logo img {
  display: block;
}

.navigation-menu {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-bottom: 0;
}

.navigation-menu li {
  margin: 0 $spacing-2;
  transition: 0.7s cubic-bezier(0.165, 0.84, 0.44, 1) all;
}

body.is-loading .navigation-menu li {
  transform: translateY(-15px);
  opacity: 0;
}

@for $i from 1 through 6 {
  .navigation-menu li:nth-of-type(#{$i}) {
    transition-delay: (0.1s * $i) + 0.3s;
  }
} 

.navigation-menu li a {
  display: block;
  padding: $spacing-5 $spacing-3;
  color: rgba(white, 0.9);
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size : 13px;
}

.navigation-menu li a:hover {
  text-decoration: none;
  color: white;
}

.navigation-menu li a:focus {
  outline: 3px solid rgba($color-secondary, 0.2)
}

.navigation-menu li.is-cta a {
  background-color: $color-secondary-d2;
  padding: $spacing-3 $spacing-5;
}

.navigation-menu li.is-cta a:hover {
  background-color: $color-secondary-d3;
}


/* Mobile Navigation
/* -------------------------------------------------------------------------- */
.navigation-mobile-container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 100;
}

.navigation-mobile-bar {
  position: fixed;
  z-index: 110;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  padding: $spacing-4;
  display: flex;
}

.navigation-mobile-bar-hamburger {
  -webkit-appearance: none;
  position: relative;
  margin-left: auto;
  background-color: white;
  box-shadow: 0 2px 4px rgba(black, 0.2);
  width: 48px;
  height: 48px;
  border: 0;
}

.navigation-mobile-bar-hamburger.is-active {
  box-shadow: none;
  background-color: transparent;
}

.navigation-mobile-bar-hamburger .icon {
  width: 20px;
  height: 20px;
  fill: $color-secondary-d5;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.navigation-mobile-bar-hamburger .icon-close {
  display: none;
}
.navigation-mobile-bar-hamburger.is-active .icon-close {
  display: block;
}

.navigation-mobile-bar-hamburger.is-active .icon-hamburger {
  display: none;
}

// Menu

.navigation-mobile-menu-container {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: $color-secondary;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $spacing-6;
  pointer-events: none;
  // opacity: 0;
  transform: translateX(100%);
  transition: 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) all;
}

.navigation-mobile-menu-container.is-active {
  visibility: visible;
  pointer-events: all;
  opacity: 1;
  transform: none;
}

.navigation-mobile-menu {
  @include m-list-unstyled;
  width: 100%;
  text-align: center;
}

.navigation-mobile-menu li a {
  display: block;
  width: 100%;
  padding: $spacing-4 $spacing-1;
  color: $color-primary-l1;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-weight: bold;
  font-family: $typeface-secondary;
  font-size: 24px;
}


/* Fixed Navigation
/* -------------------------------------------------------------------------- */
.navigation-fixed-container {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  background-color: $color-primary;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(white, 0.1);
  transition: 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) all;
  transform: translateY(-100%);
  visibility: hidden;
}

.navigation-fixed-container.is-active {
  transform: none;
  visibility: visible;
}

.navigation-fixed-menu {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  justify-content: center;
}

.navigation-fixed-menu li {
  margin: 0 $spacing-2;
}

.navigation-fixed-menu li a {
  display: block;
  padding: $spacing-5 $spacing-3;
  color: rgba(white, 0.9);
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size : 13px;
}

.navigation-fixed-menu li a:hover {
  text-decoration: none;
  color: white;
}

.navigation-fixed-menu li a:focus {
  outline: 3px solid rgba($color-secondary, 0.2)
}

.navigation-fixed-menu li.is-cta a {
  background-color: $color-secondary-d2;
  padding: $spacing-3 $spacing-5;
}

.navigation-fixed-menu li.is-cta a:hover {
  background-color: $color-secondary-d3;
}