/**
 * @base Typography definitions
 */

$typeface-primary: 'Petita', 'Montserrat', sans-serif;
$typeface-secondary: 'Bauer Bodoni', 'Didot', sans-serif;

html, body {
  font-size: 16px;
  font-family: $typeface-primary;
  line-height: 1.4;
}

a {
  color: $color-secondary;
}

a:hover {
  color: $color-secondary-l1;
}

::selection {
  background-color: rgba($color-secondary, 0.3);
}