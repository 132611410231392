/**
 * @component Form definitions
 */

/* Label elements
/* -------------------------------------------------------------------------- */
label {
  color: $color-secondary;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 1px;

  @include on-tablet {
    font-size: 12px;
  }
}

/* Input elements
/* -------------------------------------------------------------------------- */
.form-control {
  border-radius: 0;
  border: 2px solid $color-secondary-d2;
}

.form-control:focus {
  border: 2px solid white;
  box-shadow: 0 0 0 0.2rem rgba($color-secondary, .25)
}

/* Custom <select>
/* -------------------------------------------------------------------------- */
.select-control {
  position: relative;
}

.select-control select {
  border-radius: 0;
  -webkit-appearance: none;
}

.select-control:after {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  position: absolute;
  top: calc(50% - 2.5px);
  right: 12px;
  border: solid $color-secondary-d2;
  border-width: 0 2px 2px 0;
  transform: translateY(-50%) rotate(45deg);
}

/* Checkbox Group
/* -------------------------------------------------------------------------- */
.checkbox-group {
  min-height: 38px;
  display: flex;
  flex-wrap: wrap;
}

.checkbox-group label {
  text-transform: none;
  color: white;
}