/**
 * @component Buttons
 */

.btn {
  // border: 0;
  border-radius: 0;
  padding: $spacing-2 $spacing-5;
}

.btn-primary {
  background-color: $color-secondary-d2;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 14px;
}

.btn-primary:hover {
  background-color: $color-secondary-d3;
}

.btn-primary:focus,
.btn-primary:active:focus {
  outline: 3px solid rgba($color-secondary, 0.3);
  box-shadow: none;
}

.btn-primary:active {
  background-color: $color-secondary-d3 !important;
}