/**
 * @fonts Bauer Bodoni
 */

@font-face {
  font-family: 'Bauer Bodoni';
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  src: url('../fonts/bauer-bodoni/BauerBodoni-BT-Regular.woff2') format('woff2'),
       url('../fonts/bauer-bodoni/BauerBodoni-BT-Regular.woff') format('woff'),
       url('../fonts/bauer-bodoni/BauerBodoni-BT-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Bauer Bodoni';
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  src: url('../fonts/bauer-bodoni/BauerBodoni-BT-Bold.woff2') format('woff2'),
       url('../fonts/bauer-bodoni/BauerBodoni-BT-Bold.woff') format('woff'),
       url('../fonts/bauer-bodoni/BauerBodoni-BT-Bold.ttf') format('truetype');
}