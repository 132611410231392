/**
 * @component Icon component
 */

.icon {
  width: 32px;
  height: 32px;
}

.icon.icon-small {
  width: 16px;
  height: 16px;
}

.icon.icon-white {
  fill: white;
}

// Close button
$button-size: 40px;
button.close {
  position: absolute;
  top: 20px;
  right: 20px;
  opacity: 0.8;
  z-index: 1;
  pointer-events: auto;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1 !important;
  }
}

.icon-close {
  width: $button-size;
  height: $button-size;
  background-color: $color-secondary-d3;
  border: 1px solid white;
  border-radius: 50%;
  position: relative;
  display: inline-block;
  opacity: 1;

  i:before,
  i:after {
    content: "";
    width: $button-size/2;
    height: 1px;
    background-color: white;
    position: absolute;
    top: 50%;
  }

  i:before {
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
  }

  i:after {
    left: 50%;
    transform: translateX(-50%) rotate(-45deg);
  }
}

// Close button - another style
.close-CNY {
  background-color: #c49926;
  display: inline-block;
  width: $button-size/2;
  height: $button-size/2;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.close-CNY:hover{
  cursor: pointer;
}

.close-CNY:hover i::before,
.close-CNY:hover i::after {
    background: #c4dde2;
}

.close-CNY i::before,
.close-CNY i::after {
  content: '';
  position: absolute;
  height: 1px;
  width: 100%;
  top: 50%;
  left: 0;
  margin-top: -1px;
  background: white;
}

.close-CNY i::before {
  transform: rotate(45deg);
}

.close-CNY i::after {
  transform: rotate(-45deg);
}

.close-CNY.pointy i::before,
.close-CNY.pointy i::after {
  width: 200%;
  left: -50%;
}