// Colors
$brand-primary: #29b5a8;
$brand-secondary: #ff8300;
$gray: #6b7381;
$gray-light: lighten($gray, 15%);
$gray-lighter: lighten($gray, 30%);;

// Button Colors
$btn-default-color: $gray;
$btn-default-bg: $color-secondary-d2;

// Toggle Sizes
$toggle-default-size: 1.5rem;
$toggle-default-label-width: 4rem;
$toggle-default-font-size: .75rem;



// Mixin for Switch Colors
// Variables: $color, $bg, $active-bg
@mixin toggle-color($color: $btn-default-color, $bg: $btn-default-bg, $active-bg: $brand-primary) {
  color: $color;
  background: $bg;
  &.active {
    background-color: $active-bg;
  }
}

// Mixin for Default Switch Styles
// Variables: $size, $margin, $color, $bg, $active-bg, $font-size
@mixin toggle-mixin($size: $toggle-default-size, $margin: $toggle-default-label-width, $font-size: $toggle-default-font-size) {
  // color: $color;
  // background: $bg;
  margin: 0 $margin;
  padding: 0;
  position: relative;
  border: none;
  height: $size;
  width: $size * 2;
  border-radius: $size;
  
  &:focus,
  &.focus {
    &,
    &.active {
      outline: none;
    }
  }
  
  // Initial State
  .btn-language {
    font-weight: 600;
    font-size: $font-size;
    color: white;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: $size;
    display: inline-block;
    padding: 0;
    position: absolute;
    bottom: 0;
    z-index: 1;
    transition: opacity .25s;
    &:focus {
      outline: none;
    }
  }
  .btn-language.cn {
    right: $margin;
  }
  .btn-language.en {
    left: $margin;
  }
  
  > .handle {
    position: absolute;
    top: ($size * .25) / 2;
    left: ($size * .25) / 2;
    width: $size * .75;
    height: $size * .75;
    border-radius: $size * .75;
    background: #fff;
    transition: left .25s;
  }

  // Active State
  .btn-language.cn {
    &.active {
      color: $gray;
    }
    &.active ~ .handle {
      left: $size + (($size * .25) / 2);
    }
  }
  .btn-language.en {
    &.active {
      color: $gray;
    }
    &.active ~ .handle {
      left: ($size * .25) / 2;
    }
  }
}



// Apply Mixin to different sizes & colors
.btn-toggle {  
  &.btn-sm {
    @include toggle-mixin($size: 2rem, $font-size: .55rem, $margin: .5rem);
    @include toggle-color($active-bg:$color-secondary-d2);
  }
}